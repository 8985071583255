<script lang="ts">
  import Icon from '@iconify/svelte'
  import { isAuthLoading, user } from '@stores/user'
  import { Dropdown, DropdownItem, DropdownHeader, DropdownDivider, Badge } from 'flowbite-svelte'
  import { useDateTimeTranslations, useTranslations } from '@i18n/utils'
  import type { LangType } from '@customTypes/newscraft'
  import type { ui } from '@i18n/ui'
  import { LOGIN_URL, SUBSCRIBE_URL } from '@config/endpoints'

  export let lang: LangType
  export let currentUrl: string

  const t = useTranslations(lang)
  const dt = useDateTimeTranslations(lang)

  $: userStatus = `subscriptionStatus.${$user?.textStatus?.toLowerCase()}` as keyof (typeof ui)[LangType]

  const optionList = [
    {
      href: `/${lang}/user/saved/`,
      label: t('userMenu.bookmark'),
      icon: 'mdi:bookmark-multiple'
    },
    {
      href: 'https://membership.malaysiakini.com/membership/settings',
      label: t('userMenu.settings'),
      icon: 'mdi-cog'
    },
    {
      href: 'https://wa.link/53sf4k',
      label: t('userMenu.support'),
      icon: 'mdi-whatsapp'
    }
  ]

  const subscribe = {
    href: `${SUBSCRIBE_URL}?lang=${lang === 'ms' ? 'my' : lang}`,
    label: t('subscribe'),
    icon: 'mdi:gesture-tap-button'
  }

  const renew = {
    href: `${SUBSCRIBE_URL}?lang=${lang === 'ms' ? 'my' : lang}`,
    label: t('userMenu.renew'),
    icon: 'mdi:refresh'
  }

  const signIn = {
    href: `${LOGIN_URL}?lang=${lang === 'ms' ? 'my' : lang}&redirectUrl=https://www.malaysiakini.com/${lang}/`,
    label: t('signIn'),
    icon: 'mdi:login-variant'
  }

  // Show the renew subscription or subscribe link depending on user status
  if ($user?.textStatus === 'NEW') {
    optionList.unshift(subscribe)
  } else {
    optionList.unshift(renew)
  }

  const loggedOutList = [signIn, subscribe]

  const activeClass = 'bg-brandBlue text-neutral-50'
  const expiredClass = 'bg-red-300 text-slate-800'
  const newClass = 'bg-green-300 text-slate-800'
</script>

{#if !$isAuthLoading}
  <button
    class="group inline-flex size-12 items-center"
    aria-label={t('nav.openUserMenu')}
    id="user-icon"
  >
    <Icon
      icon="mdi:account-circle"
      class={`mx-auto size-6 duration-300 group-hover:text-brandOrange ${$user?.textStatus ? 'text-brandBlue' : 'text-slate-500'}`}
    />
  </button>

  <Dropdown
    triggeredBy="#user-icon"
    class="w-56 rounded-t-lg bg-indigo-50 text-sm text-slate-900"
  >
    {#if $user}
      <DropdownHeader class="px-4 py-2">
        <span class="block font-semibold text-slate-800">{$user.fullname}</span>
        <span class="block font-medium">{$user.email}</span>
      </DropdownHeader>
      <DropdownDivider class="bg-slate-200" />
      <DropdownItem class="transition duration-300 hover:bg-indigo-100">
        {#if $user.textStatus !== 'NEW'}
          <div class="flex flex-row items-center justify-start gap-x-2 text-sm">
            <span>{t('userMenu.yourSubscription')}</span>
            <Badge
              class={$user.textStatus === 'ACTIVE'
                ? activeClass
                : $user.textStatus === 'NEW'
                  ? newClass
                  : $user.textStatus === 'EXPIRED'
                    ? expiredClass
                    : ''}>{t(userStatus)}</Badge
            >
          </div>
          <span class="text-xs text-slate-700"
            >{t('userMenu.subscriptionEnds')}
            {dt({ datetime: $user.subscription_end, format: 'll' })}
          </span>
        {:else}
          <div class="px-4">
            <p>{t('userMenu.noSubscription')}</p>
          </div>
        {/if}
      </DropdownItem>

      {#each optionList as o (o.label)}
        <DropdownItem
          class="duration-300 hover:bg-indigo-100"
          href={o.href}
        >
          <span>{o.label}</span>
        </DropdownItem>
      {/each}

      <DropdownDivider class="bg-slate-200" />

      <DropdownItem
        class="flex items-center justify-between text-slate-900 duration-300 hover:bg-indigo-100"
        slot="footer"
        href={`https://membership.malaysiakini.com/auth/logout?redirectUrl=${currentUrl}`}
      >
        <span>{t('signOut')}</span>
        <Icon
          icon="mdi:logout-variant"
          class="size-6 text-brandBlue"
        />
      </DropdownItem>
    {:else}
      {#each loggedOutList as o (o.label)}
        <DropdownItem
          class="duration-300 hover:bg-indigo-100"
          href={o.href}
        >
          <span>{o.label}</span>
        </DropdownItem>
      {/each}
    {/if}
  </Dropdown>
{:else}
  <button
    class="inline-flex size-12 items-center"
    aria-label={t('nav.openUserMenu')}
    id="ts-icon"
  >
    <Icon
      icon="mdi:account-circle"
      class="mx-auto size-6 text-slate-500"
    />
  </button>
{/if}
